<template>
  <b-container>
    <b-row align-h="center" class="py-3 py-sm-4 py-md-5">
      <b-col class="col-auto col-sm-9 col-lg-6 pt-lg-5">
        <b-card style="border-radius: 0" border-variant="white">
          <div class="text-center text-medium font-weight-bold mb-4">
            {{ $t("page.permission.title") }}
          </div>
          <p
            class="text-medium mb-5"
            v-html="$t('page.permission.description-pt1')"
          ></p>
          <p
            class="text-medium"
            v-html="$t('page.permission.description-pt2')"
          ></p>
          <b-card-footer
            footer-border-variant="white"
            footer-bg-variant="white"
            class="p-0"
          >
            <b-row class="no-gutters justify-content-center align-items-center">
              <b-col class="d-flex justify-content-center">
                <b-button
                  class="text-small text-uppercase"
                  variant="secondary"
                  @click="onDisagree"
                >
                  {{ $t("button.dontProceed") }}
                </b-button>
              </b-col>
              <b-col class="d-flex justify-content-center">
                <b-button
                  @click="
                    $router.push({
                      name: 'welcome',
                      params: { pathMatch: environment },
                    });
                    setPermision(true);
                  "
                  class="text-small text-uppercase"
                  >{{ $t("button.agree") }}</b-button
                >
              </b-col>
            </b-row>
          </b-card-footer>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  computed: {
    ...mapState("settings", ["environment"]),

    ...mapState("stimulus", ["permission"]),
    endLinks() {
      return this.$store.getters["stimulus/getEndLinks"];
    },
  },

  methods: {
    ...mapMutations("stimulus", {
      setPermision: "SET_PERMISSION",
    }),
    onDisagree() {
      this.setPermision(false);
      if (this.endLinks.screenout) window.location = this.endLinks.screenout;
    },
  },
};
</script>

<style scoped>
.text-small {
  font-size: 0.7em !important;
}
.text-medium {
  font-size: 1.2em;
}
</style>
